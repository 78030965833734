import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import CircleShape1 from "../../assets/images/shape/circle-shape1.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const SB = () => {
	const { t } = useTranslation();

	return (
		<div className="services-details-info">
			<ul className="services-list">
				<li>
					<Link to="/mobility-and-decision-support-solutions">
						{t("home.SB1")}
					</Link>
				</li>

				<li>
					<Link to="/interactive-job-candidacy-and-employability">
						{t("home.SB2")}
					</Link>
				</li>
				<li>
					<Link to="/selfpromotion-and-talent-community">
						{t("home.SB3")}
					</Link>
				</li>
				<li>
					<Link to="/prequalification-rgpd-and-anonymous-candidacy">
						{t("home.SB4")}
					</Link>
				</li>

			</ul>

			<div className="circle-shape1">
				<img src={CircleShape1} alt="service" />
			</div>
		</div>
	);
};

export default SB;
