import React from "react";
import ServiceSidebar from "./ServiceSidebar";
import project5 from "../../assets/images/projects/project5.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const SC = () => {
	const { t } = useTranslation();

	return (
		<section className="services-details-area pt-100px ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
						<div className="services-details-desc">
							<span className="sub-title">
								<h2>{t("home.SCh2")}</h2>
							</span>
							<h3>{t("home.SCh31")}</h3>
							<p>{t("home.SCp1")}</p>
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-6">
									<div className="image">
										<img src={project5} alt="about" />
									</div>
								</div>

								<div className="col-lg-6 col-md-6">
									<h3>{t("home.SCh32")}</h3>
									<p>{t("home.SCp2")}</p>
									<p>{t("home.SCp3")}</p>
									<p></p>

									<div className="content">
										<h3>{t("home.SCh33")}</h3>
										<ul>
											<li>{t("home.SCi1")}</li>
											<li>{t("home.SCi2")}</li>
											<li>{t("home.SCi3")}</li>
											<h3>{t("home.SCh34")}</h3>
										</ul>
									</div>
								</div>
							</div>
							{/*
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
xxx                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
xxx                                    </div>
                                </div>


                            </div>
*/}
						</div>
					</div>

					<div className="col-lg-4 col-md-12">
						<ServiceSidebar />
					</div>
				</div>
			</div>
		</section>
	);
};

export default SC;
